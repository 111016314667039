<template>
  <div
    v-if="!institutionEmployeeLoading"
    class="clear-both grid lg:grid-cols-2 grid-cols-1 gap-4 mb-4"
  >
    <div>
      <!-- Institution Employee  -->
      <div v-if="institutionEmployee">
        <div v-if="isApproved">
          <app-card-item
            class="p-4 h-fit mb-6"
          >
            <institution-employee-card
              :resource="institutionEmployee"
              @canceled="handleInstitutionEmployeeCanceled"
              @quitted="handleInstitutionEmployeeQuit"
            />
          </app-card-item>
        </div>
      </div>

      <!-- Apply to an institution -->
      <div
        v-else
      >
        <h2 class="font-bold text-xl mb-3.5">
          {{ t('institutions.index.request.title') }}
        </h2>

        <request-form
          @applied="handleApplied"
        />
      </div>

      <div :class="!institutionEmployee ? 'mt-4' : ''">
        <app-section-title
          :title="t('institutions.index.sections.requests')"
          font-size="text-xl"
          class="mb-3.5"
        />

        <institutions-employees-table
          mode="employee"
          @approved="handleApproved"
          @declined="handleDeclined"
          @canceled="handleCanceled"
          @removed="handleRemoved"
        />
      </div>
    </div>

    <div>
      <institutions-broadcast
        v-if="institutionEmployee"
        :resource="institutionEmployee"
      />

      <institutions-documents
        v-if="institutionEmployee"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import {
  fetchInstitutionEmployeeLastAsEmployee as apiFetchInstitutionEmployeeLastAsEmployee,
} from '@shared/http/api'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import RequestForm from '@extranet/components/resources/institution_employee/request_form/RequestForm.vue'
import InstitutionEmployeeCard from '@extranet/components/resources/institution_employee/InstitutionEmployeeCard.vue'
import InstitutionsEmployeesTable from '@extranet/components/institutions_employees/InstitutionsEmployeesTable.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import InstitutionsBroadcast from '@extranet/components/institutions/InstitutionsBroadcast.vue'
import InstitutionsDocuments from '@extranet/components/institutions/InstitutionsDocuments.vue'

const store = useStore()
const { t } = useI18n()

// ---------- INSTITUTION EMPLOYEE ----------

const institutionEmployee = ref(null)
const institutionEmployeeLoading = ref(false)

function fetchInstitutionEmployee() {
  store.commit('spinner/ENABLE')
  institutionEmployeeLoading.value = true

  apiFetchInstitutionEmployeeLastAsEmployee()
    .then((response) => {
      institutionEmployee.value = response.data.data
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
      institutionEmployeeLoading.value = false
    })
}

function handleApplied(pendingInstitutionEmployee) {
  institutionEmployee.value = pendingInstitutionEmployee
  fetchInstitutionEmployee()
}

function handleInstitutionEmployeeCanceled() {
  institutionEmployee.value = null
  fetchInstitutionEmployee()
}

async function handleInstitutionEmployeeQuit() {
  store.commit('loading/ENABLE')
  await store.dispatch('auth/getAuthenticatedUser')
  store.commit('loading/DISABLE')
  fetchInstitutionEmployee()
}

const isApproved = computed(() => (
  institutionEmployee.value.attributes.state === 'approved'
))

// ---------- ACTIONS ----------

function handleApproved() {
  fetchInstitutionEmployee()
}

function handleDeclined() {
  fetchInstitutionEmployee()
}

function handleCanceled() {
  fetchInstitutionEmployee()
}

function handleRemoved() {
  fetchInstitutionEmployee()
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchInstitutionEmployee()
})
</script>
